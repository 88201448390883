import logo from './logo.svg';
import './App.css';
import { Unity, useUnityContext } from "react-unity-webgl";
function App() {
  console.log = console.warn = console.error = () => {};
    const {  unityProvider, isLoaded, loadingProgression } = useUnityContext({
      loaderUrl: "WebGL Build/Build/WebGL Build.loader.js",
      dataUrl: "WebGL Build/Build/WebGL Build.data",
      frameworkUrl: "WebGL Build/Build/WebGL Build.framework.js",
      codeUrl: "WebGL Build/Build/WebGL Build.wasm",
    });
    const loadingPercentage = Math.round(loadingProgression * 100);
    return (
    // return <Unity style={{height:'100vh',width:'100%'}} unityProvider={unityProvider} />;
<><div className="container">
      {isLoaded === false && (
        // Application is not loaded.
        <div className="loading-overlay">
          <p>Loading... ({loadingPercentage}%)</p>
        </div>
      )}
    </div> 
      <Unity style={{height:'100vh',width:'100%'}} className="unity" unityProvider={unityProvider} />
      </>
      )

}

export default App;
